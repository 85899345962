document.addEventListener("DOMContentLoaded", function () {
  const closeButton = document.getElementById("closeModal");
  const modal = document.getElementById("myModal");
  const allFocusableElements = document.querySelectorAll(
    "a, button, input, select, textarea, [tabindex]"
  );
  let focusedElementBeforeModal;

  setTimeout(() => {
    // フォーカス可能な要素を無効化
    function disableFocusableElements() {
      allFocusableElements.forEach((el) => {
        if (!modal.contains(el)) {
          el.setAttribute("tabindex", "-1");
        }
      });
    }

    // フォーカス可能な要素を復元
    function enableFocusableElements() {
      allFocusableElements.forEach((el) => {
        if (!modal.contains(el)) {
          el.removeAttribute("tabindex");
        }
      });
    }

    // モーダルを開く関数
    function openModal() {
      focusedElementBeforeModal = document.activeElement;
      modal.style.display = "block";
      modal.setAttribute("aria-hidden", "false");
      closeButton.focus();
      disableFocusableElements();
    }

    // モーダルを閉じる関数
    function closeModal() {
      modal.style.display = "none";
      modal.setAttribute("aria-hidden", "true");
      enableFocusableElements();
      if (focusedElementBeforeModal) focusedElementBeforeModal.focus();
    }

    // イベントリスナー
    closeButton.addEventListener("click", closeModal);

    // ESCキーで閉じる
    document.addEventListener("keydown", function (event) {
      if (event.key === "Escape" && modal.style.display === "block") {
        closeModal();
      }
    });

    // モーダル外クリックで閉じる
    window.addEventListener("click", function (event) {
      if (event.target === modal) closeModal();
    });

    openModal();
  }, "1000");
});

window.addEventListener('scroll', () => {
  const scrollThreshold = 100; // バナーを表示するスクロール量 (px)
  if (window.scrollY > scrollThreshold && !banner.classList.contains('show')) {
    banner.classList.remove('hidden');
    banner.classList.add('show');
  }
});

// バナーを閉じるボタンのクリックイベント
const closeBanner = document.querySelector('.close-banner');
const banner = document.querySelector('.banner');
closeBanner.addEventListener('click', () => {
  // banner.style.display = "none";
      banner.classList.add('close');
      banner.inert = true;

});


const skip = document.getElementById("skip");
var mov = document.getElementById("movPc");

if (window.matchMedia("(max-width: 769px)").matches) {
  mov = document.getElementById("movSp");
}

window.addEventListener("load", function () {
  mov.addEventListener("ended", function () {
    document.querySelector(".main_mov").classList.add("out");
    setTimeout(() => {
      document.querySelector(".main_mov").classList.add("hdn");
      mainAnime();
    }, "1000");
    skip.classList.add("no_show");
    // document.querySelector('.mainSld').classList.add('show');
    // $('.mainSld').slick('slickPlay');
  });
});

// スキップされた時
skip.addEventListener("click", function () {
  document.querySelector(".main_mov").classList.add("out");
  mov.pause();
  setTimeout(() => {
    document.querySelector(".main_mov").classList.add("hdn");
  }, "1000");
  this.classList.add("no_show");

  // document.querySelector('.mainSld').classList.add('show');
  // $('.mainSld').slick('slickPlay');

  // document.querySelector('.text_area1').classList.add('no_show');
  // document.querySelector('.text_area2').classList.add('no_show');
  //
  // document.querySelector('.grass').classList.add('no_active');
  // setTimeout(() => {
  //   document.querySelector('.grass').classList.add('hdn');
  // }, "1100")
  //
  // setTimeout(() => {
  //   document.querySelector('.main_img').classList.add('show');
  //   document.querySelector('.imgbox1').classList.add('move');
  // }, "1000")
  // setTimeout(() => {
  //   document.querySelector('.imgbox2').classList.add('show');
  //   document.querySelector('.imgbox3').classList.add('show');
  //   document.querySelector('.imgbox4').classList.add('show');
  //   document.querySelector('.imgbox5').classList.add('show');
  //   document.querySelector('.imgbox6').classList.add('show');
  //   document.querySelector('.imgbox7').classList.add('show');
  //   document.querySelector('.imgbox8').classList.add('show');
  //   document.querySelector('.imgbox9').classList.add('show');
  // }, "7000")
  // setTimeout(() => {
  //   document.querySelector('.imgbox10').classList.add('show');
  //   document.querySelector('.imgbox10_note').classList.add('show');
  // }, "8500")
});

// $('.mainSld').slick({
//   autoplay: false,
//   autoplaySpeed: 4000,
//   speed: 2000,
//   dots: false,
//   arrows: false,
//   fade: true,
//   pauseOnHover: false,
//   pauseOnFocus: false,
// });

// スクロールしたらMVと重なる
// if (window.matchMedia('(max-width: 769px)').matches) {
//   var topPlxSpeed1 = 0;
//   var allHeight = '5000 center';
//   var height = '1000 center';
// }else{
//   var topPlxSpeed1 = -8;
//   var allHeight = '10000 center';
//   var height = '2000 center';
// }
// var rellax_1 = new Rellax('.topRellax_1', {
//   speed: topPlxSpeed1,
//   center: true,
//   wrapper: null,
//   round: true,
//   vertical: true,
//   horizontal: false
// });

// $(function() {
//   $('.slide').slick({
//     responsive: [
//       {
//         breakpoint: 768,
//         settings: {
//           arrows: false,
//           centerMode: true,
//           centerPadding: '40px',
//           slidesToShow: 3
//         }
//       }
//     ]
//   });
// });

gsap.fromTo(
  ".sl02",
  {
    autoAlpha: 0,
  },
  {
    autoAlpha: 1,
    scrollTrigger: {
      trigger: ".slsc_02",
      start: "top top",
      end: "bottom top",
      toggleActions: "play none none reverse",
      scrub: true,
      markers: false,
    },
  }
);
gsap.fromTo(
  ".sl03",
  {
    autoAlpha: 0,
  },
  {
    autoAlpha: 1,
    scrollTrigger: {
      trigger: ".slsc_03",
      start: "top top",
      end: "bottom top",
      toggleActions: "play none none reverse",
      scrub: true,
      markers: false,
    },
  }
);
gsap.fromTo(
  ".sl04",
  {
    autoAlpha: 0,
  },
  {
    autoAlpha: 1,
    scrollTrigger: {
      trigger: ".slsc_04",
      start: "top top",
      toggleActions: "play none none reverse",
      scrub: true,
      markers: false,
    },
  }
);

// //gsap
// // 背景画像切り替え
// const fixBg = gsap.timeline({
//   ease: 'none',
//   scrollTrigger: {
//     trigger: ".main",
//     start: "center center",
//     toggleClass: {targets: '.fixBg', className: "active"},
//   }
// });
//
// // 左のnav
// const sideNavBox = gsap.timeline({
//   ease: 'none',
//   scrollTrigger: {
//     trigger: ".main",
//     start: "center center",
//     end: "3400px center",
//     toggleClass: {targets: '.sidenav,.scrollnav', className: "active"},
//   }
// });
//
//
// //1
// const sideNav1 = gsap.timeline({
//   ease: 'none',
//   scrollTrigger: {
//     trigger: ".sec2 .sld1",
//     start: "center center",
//     end: height,
//     toggleClass: {targets: '.sec2 .nav1', className: "active"},
//   }
// });
//
// const sld1Gsap = gsap.timeline({
//   ease: 'none',
//   scrollTrigger: {
//     trigger: ".sec2 .sld1",
//     start: "center center",
//     end: height,
//     scrub: true,
//     pin: true,
//     markers: false,
//   }
// });
//
// sld1Gsap
// .to(".fixBg .sldBg1", {
//     autoAlpha: 1,
//   },"-=1"
// )
//
// .to(".sec2 .sld1", {
//     autoAlpha: 1,
//   },"1"
// )
// .to(".sec2 .sld1 .text_area", {
//   autoAlpha: 1,
//   },"1"
// )
//
// .to(".sec2 .sld1 .img_area .img1", {
//   x: 0,
//   autoAlpha: 1,
//   },"2"
// )
// .to(".sec2 .sld1 .img_area .img2", {
//   x: 0,
//   autoAlpha: 1,
//   },"2"
// )
// .to(".sec2 .sld1", {
//   x: 0,
//   autoAlpha: 0,
//   },"4"
// );
//
//
// //2
// const sideNav2 = gsap.timeline({
//   ease: 'none',
//   scrollTrigger: {
//     trigger: ".sec2 .sld2",
//     start: "center center",
//     end: height,
//     toggleClass: {targets: '.sec2 .nav2', className: "active"},
//   }
// });
//
// const sld2Gsap = gsap.timeline({
//   ease: 'none',
//   scrollTrigger: {
//     trigger: ".sec2 .sld2",
//     start: "center center",
//     end: height,
//     scrub: true,
//     pin: true,
//     markers: false,
//   }
// });
//
// sld2Gsap
// .to(".fixBg .sldBg2", {
//     autoAlpha: 1,
//   },"-=1"
// )
//
// .to(".sec2 .sld2", {
//     autoAlpha: 1,
//   },"1"
// )
// .to(".sec2 .sld2 .text_area", {
//   autoAlpha: 1,
//   },"1"
// )
//
// .to(".sec2 .sld2 .img_area .img1", {
//   x: 0,
//   autoAlpha: 1,
//   },"2"
// )
// .to(".sec2 .sld2 .img_area .img2", {
//   x: 0,
//   autoAlpha: 1,
//   },"2"
// )
// .to(".sec2 .sld2", {
//   x: 0,
//   autoAlpha: 0,
//   },"4"
// );
//
//
// //3
// const sideNav3 = gsap.timeline({
//   ease: 'none',
//   scrollTrigger: {
//     trigger: ".sec2 .sld3",
//     start: "center center",
//     end: height,
//     toggleClass: {targets: '.sec2 .nav3', className: "active"},
//   }
// });
//
// const sld3Gsap = gsap.timeline({
//   ease: 'none',
//   scrollTrigger: {
//     trigger: ".sec2 .sld3",
//     start: "center center",
//     end: height,
//     scrub: true,
//     pin: true,
//     markers: false,
//   }
// });
//
// sld3Gsap
// .to(".fixBg .sldBg3", {
//     autoAlpha: 1,
//   },"-=1"
// )
//
// .to(".sec2 .sld3", {
//     autoAlpha: 1,
//   },"1"
// )
// .to(".sec2 .sld3 .text_area", {
//   autoAlpha: 1,
//   },"1"
// )
//
// .to(".sec2 .sld3 .img_area .img1", {
//   x: 0,
//   autoAlpha: 1,
//   },"2"
// )
// .to(".sec2 .sld3 .img_area .img2", {
//   x: 0,
//   autoAlpha: 1,
//   },"2"
// )
// .to(".sec2 .sld3", {
//   x: 0,
//   autoAlpha: 0,
//   },"4"
// );
//
// //5
// const sideNav5 = gsap.timeline({
//   ease: 'none',
//   scrollTrigger: {
//     trigger: ".sec2 .sld5",
//     start: "center center",
//     end: height,
//     toggleClass: {targets: '.sec2 .nav5', className: "active"},
//   }
// });
//
// const sld5Gsap = gsap.timeline({
//   ease: 'none',
//   scrollTrigger: {
//     trigger: ".sec2 .sld5",
//     start: "center center",
//     end: height,
//     scrub: true,
//     pin: true,
//     markers: false,
//   }
// });
//
// sld5Gsap
// .to(".fixBg .sldBg5", {
//     autoAlpha: 1,
//   },"-=1"
// )
//
// .to(".sec2 .sld5", {
//     autoAlpha: 1,
//   },"0"
// )
// .to(".sec2 .sld5 .text_area", {
//     autoAlpha: 1,
//   },"0"
// )
//
// .to(".sec2 .sld5 .img_area .img1", {
//     x: 0,
//     autoAlpha: 1,
//   },"0"
// )
// .to(".sec2 .sld5 .img_area .img2", {
//     x: 0,
//     autoAlpha: 1,
//   },"0"
// )
// .to(".sec2 .sld5", {
//     x: 0,
//     autoAlpha: 0,
//   },"2"
// );
//
// //7
// const sideNav7 = gsap.timeline({
//   ease: 'none',
//   scrollTrigger: {
//     trigger: ".sec2 .sld7",
//     start: "center center",
//     end: height,
//     toggleClass: {targets: '.sec2 .nav7', className: "active"},
//   }
// });
//
// const sld7Gsap = gsap.timeline({
//   ease: 'none',
//   scrollTrigger: {
//     trigger: ".sec2 .sld7",
//     start: "center center",
//     end: height,
//     scrub: true,
//     pin: true,
//     markers: false,
//   }
// });
//
// sld7Gsap
// .to(".fixBg .sldBg7", {
//     autoAlpha: 1,
//   },"-=1"
// )
//
// .to(".sec2 .sld7", {
//     autoAlpha: 1,
//   },"0"
// )
// .to(".sec2 .sld7 .text_area", {
//     autoAlpha: 1,
//   },"0"
// )
//
// .to(".sec2 .sld7 .img_area .img1", {
//     x: 0,
//     autoAlpha: 1,
//   },"0"
// )
// .to(".sec2 .sld7 .img_area .img2", {
//     x: 0,
//     autoAlpha: 1,
//   },"0"
// )
// .to(".sec2 .sld7", {
//     x: 0,
//     autoAlpha: 0,
//   },"2"
// );
